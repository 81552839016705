<template>
  <div class="d-flex flex-wrap mt-2 container">
    <!-- <div class="container mb-2">    
    <v-divider class="mx-4" color="black"></v-divider>
    <h1 class="text-center display-3 font-weight-light mt-2 mb-2">Bestseller</h1>
    <v-divider class="mx-4 mb-6" color="black"></v-divider>
    <backtohome></backtohome>
    </div> -->
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/drachenmakiseitlichmitlogo.png" height="200px"></v-img>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121; padding-top:0;"

          >
          Drachenmaki
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (8 Stk.)<br> 14,90 €
            </v-card-subtitle>
        </v-card>
      </v-col>
        <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/tempuracaliforniacutted.png" height="200px"></v-img>
          <v-alert
            dense
            max-width="50%"
            color="green"
            class="text-center mx-auto mt-4"
          >
            NEUHEIT
          </v-alert>
          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121; padding-top:0;"
          >
            Tempura California Maki
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (12 Stk.)<br> 12,20 €
            </v-card-subtitle>
        </v-card>
      </v-col>
                    <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/rainbowmakiohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Rainbow Makis
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (12 Stk.)<br> 11,30 €
            </v-card-subtitle>
        </v-card>
      </v-col>
              <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/makiimsetohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Maki im Set
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (18 Stk.)<br> 11,90 €
            </v-card-subtitle>
        </v-card>
      </v-col>
        <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/sushiohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Sushi Set groß
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (11 Stk. & 3 Stk. Maki)<br> 11,90 €
            </v-card-subtitle>
        </v-card>
      </v-col>
              <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/minifruehlingsrolleohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Mini Frühlingsrollen
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            (5 Stk.)<br> 3,70 €
            </v-card-subtitle>
        </v-card>
      </v-col>
                    <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/pikantesuppeohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Süß-Sauer-Scharf Suppe
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            3,70 €
            </v-card-subtitle>
        </v-card>
      </v-col>
                          <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" max-width="344" style="background-color: #212121;">
          <v-img src="../assets/images/misosuppeohnehintergrund.png" height="200px"></v-img>

          <v-card-title
            class="justify-center white--text"
            style="background-color: #212121;"
          >
            Miso Suppe
          </v-card-title>

          <v-card-subtitle class="text-center white--text" style="background-color: #212121;">
            3,20 €
            </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Backtohome from './Backtohome.vue';
export default {
  // components: { Backtohome },
  name: 'Bestseller',
  data() {
    return {};
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
