<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        special Makis
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
        ><template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: {
  //   Backtospeisekarte,
  // },
  name: 'Makispecial',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/rainbowmakifrontal.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/rainbowmakivogelperspektive.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/drachenmakifrontal.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/drachenmakivogelperspektive.jpg'),
          title: '',
        },
                {
          src: require('../assets/images/tempuracaliforniafrontalshow.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Anzahl', value: 'anzahl' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'N8',
          speise: 'Futo Maki',
          anzahl: '(10 Stk.)',
          allergene: '(B, C)',
          preis: '11,40 €',
        },
        {
          nummer: 'N9',
          speise: 'Tempura Maki (mit Garnelen, Huhn o. Ente)',
          anzahl: '(10 Stk.)',
          allergene: '(A, R, D, F)',
          preis: '11,70 €',
        },
        {
          nummer: 'N10',
          speise: 'Lachs California Maki klein / groß',
          anzahl: '(6 Stk. / 12 Stk.)',
          allergene: '(A, F, C, D, G, N)',
          preis: '5,50 € / 10,90 €',
        },
        {
          nummer: 'N12',
          speise: 'Rainbow Maki klein / groß',
          anzahl: '(6 Stk. / 12 Stk.)',
          allergene: '(A, F, C, D, G, R, N)',
          preis: '5,90 € / 11,30 €',
        },
        {
          nummer: 'N13',
          speise: 'Avocado California klein / groß',
          anzahl: '(6 Stk. / 12 Stk.)',
          allergene: '',
          preis: '5,90 € / 11,30 €',
        },
        {
          nummer: 'N14',
          speise:
            'Tempura California (mit Garnelen & Teriyaki Sauce) klein / groß',
          anzahl: '(6 Stk. / 12 Stk.)',
          allergene: '(A, F, R, N',
          preis: '6,20 € / 12,20 €',
        },
        {
          nummer: 'N15',
          speise: 'Thunfisch California klein / groß',
          anzahl: '(6 Stk. / 12 Stk.)',
          allergene: '(A, F, C, D, G)',
          preis: '6,50 € / 12,90 €',
        },
        {
          nummer: 'N16',
          speise: 'Drachen Maki',
          anzahl: '(8 Stk.)',
          allergene: '(A, F, C, D, G, R)',
          preis: '14,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
