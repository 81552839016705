<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <v-card>
      <v-card-title class="display-3">
        Bento Lunchbox
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="desserts" :search="search" :items-per-page="-1">
        <template v-slot:items="{ item }"
          ><b>{{ item.desserts.speise }}</b></template
        ><template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Bentolunchboxkarte',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/bentoboxrechts.jpg'),
        },
        {
          src: require('../assets/images/bentoboxfrontalmithintergrund.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Inhalt', value: 'inhalt' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: 'B1',
          speise: 'Sushi Bento',
          inhalt: '(4 Sushi, 6 Maki und Obst)',
          allergene: '(A, B, C, D, F, G, R, O)',
          preis: '9,50 €',
        },
        {
          nummer: 'B2',
          speise: 'Sashimi Bento',
          inhalt: '(6 Sashimi, 2 Sushi, 3 Maki und Obst)',
          allergene: '(A, B, C, D, F, G, R, O)',
          preis: '9,50 €',
        },
        {
          nummer: 'B3',
          speise: 'Maki Bento',
          inhalt: '(12 Maki und Obst)',
          allergene: '(A, B, C, D, F, G, H, R, O)',
          preis: '9,50 €',
        },
        {
          nummer: 'B4',
          speise: 'Tempura Bento ',
          inhalt: '(gebackene Garnelen, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, F, G, H, O, R)',
          preis: '9,50 €',
        },
        {
          nummer: 'B5',
          speise: 'Vegetarisches Bento',
          inhalt: '(gebackener Tofu mit Gemüse, 3 Makis, 2 Sushi und Obst',
          allergene: '(A, B, C, F, G, H, O)',
          preis: '9,50 €',
        },
        {
          nummer: 'B6',
          speise: 'Lachs Bento',
          inhalt: '(gegrillter Lachs, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, D, E, F, G, H)',
          preis: '9,50 €',
        },
        {
          nummer: 'B7',
          speise: 'Yakitori Bento ',
          inhalt: '(gegrillter Hühnerspieß, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, D, E, F, G, H)',
          preis: '9,50 €',
        },
        {
          nummer: 'B8',
          speise: 'Tori Bento ',
          inhalt:
            '(gebratenes Hühnerfleisch mit Gemüse, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, N)',
          preis: '9,50 €',
        },
        {
          nummer: 'B9',
          speise: 'Acht Schätze Bento (scharf)',
          inhalt: '(3 Maki, 2 Sushi und Obst)',
          allergene: '(A, C, E, H, N)',
          preis: '9,50 €',
        },
        {
          nummer: 'B10',
          speise: 'Misoyaki Bento',
          inhalt: '(gegrilter Butterfisch, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, C, E, H, N)',
          preis: '9,50 €',
        },
        {
          nummer: 'B11',
          speise: 'Thunfisch Bento ',
          inhalt: '(gegrillter Thunfisch, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, N)',
          preis: '9,50 €',
        },
        {
          nummer: 'B12',
          speise: 'Rindfleisch Bento ',
          inhalt: '(gegrilltes Rindfleisch, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, D, E, F, H, O)',
          preis: '9,50 €',
        },
        {
          nummer: 'B13',
          speise: 'Akame Bento',
          inhalt: '(knusprige Ente oder Huhn, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, D, F, H)',
          preis: '9,50 €',
        },
        {
          nummer: 'B14',
          speise: 'Sesam Bento',
          inhalt:
            '(gebackenes Hühnerfleisch mit Sesam, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, F, H, N)',
          preis: '9,50 €',
        },
        {
          nummer: 'B15',
          speise: 'Bento süß-sauer ',
          inhalt: '(Hühnerfleisch süß-sauer, 3 Maki, 2 Sushi und Obst)',
          allergene: '(A, B, C, F, H, N)',
          preis: '9,50 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
