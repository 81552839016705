<template>
  <div class="home">
    <v-carousel hide-delimiters cycle>
      <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
        <v-row class="fill-height" align="center" justify="center">
          <div class="text-h1 white--text">
            {{ item.title }}
          </div>
        </v-row></v-carousel-item
      >
    </v-carousel>
    <!-- <div class="container">
      <backtospeisekarte></backtospeisekarte>
    </div> -->
    <v-card>
      <v-card-title class="display-3">
        Maki
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.anzahl1="{ item }">
          <b>{{ item.anzahl1 }}</b>
        </template>
        <template v-slot:item.anzahl3="{ item }">
          <b>{{ item.anzahl3 }}</b>
        </template>
        <template v-slot:item.anzahl4="{ item }">
          <b>{{ item.anzahl4 }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtospeisekarte from './Backtospeisekarte.vue';
export default {
  // components: {
  //   Backtospeisekarte,
  // },
  name: 'Maki',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/makinormalmithintergrundfrontal.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/makinormalvogelperspektive.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/makiimsetseitlich.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/makiimsetoben.jpg'),
          title: '',
        },
        {
          src: require('../assets/images/makiimsethintergrund.jpg'),
          title: '',
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: '6 Stk.', value: 'anzahl1' },
        { text: '12 Stk.', value: 'anzahl3' },
        { text: '18 Stk.', value: 'anzahl4' },
      ],
      desserts: [
        {
          nummer: 'N1',
          speise: 'Lachs Maki',
          allergene: '(A, F, D)',
          anzahl1: '4,10 €',
          anzahl3: '7,00 €',
          anzahl4: '10,00 €',
        },
        {
          nummer: 'N2',
          speise: 'Thunfisch Maki',
          allergene: '(A, F, D)',
          anzahl1: '4,10 €',
          anzahl3: '7,00 €',
          anzahl4: '10,00 €',
        },
        {
          nummer: 'N3',
          speise: 'Garnelen Maki',
          allergene: '(A, F, R)',
          anzahl1: '4,10 €',
          anzahl3: '7,00 €',
          anzahl4: '10,00 €',
        },
        {
          nummer: 'N4',
          speise: 'California Maki',
          allergene: '(A, F, R, G)',
          anzahl1: '4,10 €',
          anzahl3: '7,00 €',
          anzahl4: '11,90 €',
        },
        {
          nummer: 'N5',
          speise: 'Gurken Maki',
          allergene: '(N)',
          anzahl1: '3,80 €',
          anzahl3: '6,30 €',
          anzahl4: '8,30 €',
        },
        {
          nummer: 'N6',
          speise: 'Oshinko Maki',
          allergene: '(N)',
          anzahl1: '3,80 €',
          anzahl3: '6,30 €',
          anzahl4: '8,30 €',
        },
        {
          nummer: 'N11',
          speise: 'Avocado Maki',
          allergene: '(N)',
          anzahl1: '4,00 €',
          anzahl3: '6,80 €',
          anzahl4: '9,00 €',
        },
        {
          nummer: 'N7',
          speise: 'Maki im Set',
          allergene: '(A, F, D, N, R)',
          anzahl1: '/',
          anzahl3: '9,90 €',
          anzahl4: '11,90 €',
        },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
