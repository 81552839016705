<template>
  <div class="home">
    <v-carousel
      hide-delimiters
      hide-arrows
      :left-control-icon="false"
      :right-control-icon="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    <!-- <div class="container">
      <backtohauptspeise></backtohauptspeise>
    </div> -->
    <v-card>
      <v-card-title class="display-2">
        Hühnerfleisch
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :items-per-page="-1"
      >
        <template v-slot:item.speise="{ item }">
          <b>{{ item.speise }}</b>
        </template>
        <template v-slot:item.preis="{ item }">
          <b>{{ item.preis }}</b>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
// import Backtohauptspeise from './Backtohauptspeise.vue';
export default {
  // components: { Backtohauptspeise },
  name: 'Huehnerfleisch',
  data() {
    return {
      items: [
        {
          src: require('../assets/images/gebrateneshuehnerfleischmithintergrund.jpg'),
        },
        {
          src: require('../assets/images/gebrateneshuehnerfleischmithintergrundseitlich.jpg'),
        },
      ],
      search: '',
      headers: [
        {
          text: 'Nummer',
          align: 'start',
          sortable: false,
          value: 'nummer',
        },
        { text: 'Speise', value: 'speise' },
        { text: 'Allergene', value: 'allergene' },
        { text: 'Preis', value: 'preis' },
      ],
      desserts: [
        {
          nummer: '95',
          speise: 'Hühnerfleisch mit Thai Sauce (scharf)',
          allergene: '(A, F)',
          preis: '10,80 €',
        },
        {
          nummer: '96',
          speise: 'Hühnerfleisch Ganbian (scharf)',
          allergene: '(A, F)',
          preis: '11,80 €',
        },
        {
          nummer: '97',
          speise: 'Hühnerfleisch nach Chop Suey',
          allergene: '(A, F)',
          preis: '10,80 €',
        },
        {
          nummer: '98',
          speise: 'Hühnerfleisch nach Sichuan Art (scharf)',
          allergene: '(A,F)',
          preis: '10,80 €',
        },
        {
          nummer: '99',
          speise: 'Hühnerfleisch mit Bambus und Pilzen',
          allergene: '(A,F)',
          preis: '10,80 €',
        },
        {
          nummer: '100',
          speise: 'Knuspriges Huhn',
          allergene: '(A,F)',
          preis: '10,50 €',
        },
        {
          nummer: '101',
          speise: 'Gebratenes Hühnerfleisch mit Gemüse',
          allergene: '(A,F)',
          preis: '10,50 €',
        },
        {
          nummer: '102',
          speise: 'Gebratenes Hühnerfleisch mit Paprika',
          allergene: '(A, F)',
          preis: '10,50 €',
        },
        {
          nummer: '103',
          speise: 'Gebratenes Hühnerfleisch mit Curry',
          allergene: '(A, F)',
          preis: '10,50 €',
        },
        {
          nummer: '104',
          speise: 'Hühnerfleisch mit Ananas',
          allergene: '(A, F)',
          preis: '10,50 €',
        },
        {
          nummer: '105',
          speise: 'Hühnerfleisch mit süß-sauer Sauce',
          allergene: '(A, F)',
          preis: '10,50 €',
        },
        // {
        //   nummer: '106',
        //   speise: 'Gebratenes Hühnerfleisch mit Zucchini',
        //   allergene: '(A, F)',
        //   preis: '9,70 €',
        // },
        {
          nummer: '107',
          speise: 'Gebratenes Hühnerfleisch mit Gemüse und Erdnüsse',
          allergene: '(A, E, F)',
          preis: '10,50 €',
        },
        // {
        //   nummer: '108',
        //   speise: 'Hühnerspieß mit Gemüse',
        //   allergene: '(A, F)',
        //   preis: '9,70 €',
        // },
        {
          nummer: '109',
          speise: 'Hühnerfleisch mit Teriyaki Sauce',
          allergene: '(A, F)',
          preis: '10,50 €',
        },
        // {
        //   nummer: '110',
        //   speise: 'Hühnerfleisch mit Zwiebeln',
        //   allergene: '(A, F)',
        //   preis: '9,70 €',
        // },
        {
          nummer: '111',
          speise: 'Hühnerfleisch mit Sesam',
          allergene: '(A, C, F, N)',
          preis: '10,50 €',
        },
        // {
        //   nummer: '112',
        //   speise: 'Hühnerfleisch mit Tofu und Gemüse',
        //   allergene: '(A, F)',
        //   preis: '10,00 €',
        // },
      ],
    };
  },
  methods: {
    top() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.top();
  },
};
</script>

<style lang="scss" scoped></style>
